export default function popup() {
    $(document).on('click', '[data-popup]', function (e) {
        e.preventDefault();

        let url = $(this).attr('href');

        if (!url) {
            return;
        }

        let width = 650,
            height = 400;

        let top = window.top.outerHeight / 2 + window.top.screenY - (height / 2),
            left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

        let newWindow = window.open(url, 'Share', `width=${width}, height=${height}, top=${top}, left=${left}`);

        if (!window.focus) {
            return
        }

        newWindow.focus()
    });
}
