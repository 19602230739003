import { lockScroll, unlockScroll } from '@fhoke/helpers';

export default function menu() {
    //---- Prevent click on empty links
    $(document).on('click', '.site-menu .menu-item > a[href="#"]', function (e) {
        e.preventDefault();
    });

    //---- Toggle
    $(document).on('click', '[data-site-menu="opener"]', function (e) {
        e.preventDefault();

        let button = $(this);
        let wrapper = $('[data-site-menu="target"]');

        button.toggleClass('active');
        wrapper.fadeToggle(Slate.transitions.speed);

        if (button.hasClass('active')) {
            lockScroll(wrapper[0]);
        } else {
            unlockScroll();
        }
    });

    //---- Toggle submenus (touch devices)
    $(document).on('click', '.mobile-menu .menu-item-toggle', function (e) {
        e.preventDefault();

        let wrap = $(this).closest('.menu-item-has-children');

        wrap.toggleClass('active');
        wrap.find('.sub-menu').slideToggle(Slate.transitions.speed);
    });

    //---- Remove inline CSS display from menu on window resize
    $(window).on('resize', function () {
        if (Slate.window.width <= Slate.breakpoints.menu) {
            return;
        }

        $('[data-site-menu="opener"]').removeClass('active');
        $('[data-site-menu="target"]').css('display', '');
    });
}
