export function formFields() {
    document.querySelectorAll('input[type="checkbox"], input[type="radio"]').forEach(function (input) {
        input.addEventListener('change', function () {
            document.querySelectorAll('input[type="checkbox"], input[type="radio"]').forEach(function (input) {
                let inputId = input.getAttribute('id');

                if (input.checked) {
                    input.parentElement.classList.add('checked');

                    if (inputId) {
                        document.querySelector('label[for="' + inputId + '"]').classList.add('checked');
                    }
                } else {
                    input.parentElement.classList.remove('checked');

                    if (inputId) {
                        document.querySelector('label[for="' + inputId + '"]').classList.remove('checked');
                    }
                }
            });
        });
    });
}

export default function helpers() {
    formFields();

    //---- Mute jQuery migrate
    if ($.migrateMute !== undefined) {
        $.migrateMute = true;
    }

    //---- Remove 'no-touch' class for touch devices
    if (Slate.isTouchDevice) {
        $('body').removeClass('no-touch');
    }

    //---- Remove 'no-js' class when JavaScript is enabled
    $('body').removeClass('no-js');

    //---- Turn off autocomplete on datepickers
    $(document).find('.datepicker').attr('autocomplete', 'off');

    //---- Table Wrapper (to make responsive)
    $(document).find('.txt-styles table').wrap('<div class="table-wrap"></div>');

    //---- FitVids
    $(document).find('iframe[src^="https://www.youtube.com"], iframe[src^="https://player.vimeo.com"]').wrap('<div class="video-wrapper"></div>');

    //---- Select2
    $('select:not(.select2-ignore)').wrap('<div class="select2-wrapper"></div>');

    $(document).find('.select2-wrapper select').each(function () {
        let select = $(this);

        select.select2({
            dropdownParent: select.parents('.select2-wrapper'),
        });
    });

    $('.pll-switcher-select').on('select2:select', function (e) {
        window.location.href = e.params.data.id;
    });
}
