export default function copy() {
    $(document).on('click', '[data-copy]', function (e) {
        e.preventDefault();

        let url = $(this).attr('href');

        $('body').append('<input type="text" id="copy-text" value="' + url + '" style="display: none;" />');
        var copyText = document.getElementById("copy-text");
        copyText.value = url;

        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        navigator.clipboard.writeText(copyText.value);

        $('#copy-text').remove();

        alert('Link copied to clipboard!');
    });
}
