
import mixpanel from "mixpanel-browser";

function getLinkData(element) {
    let label = $(element).text().trim();

    if ($(element).hasClass('btn')) {
        label = $(element).find('span:first-child').text().trim();
    }

    return {
        label: label,
    }
}

export function mixpanelInit() {
    const enabled = SlateAjax.mixpanel.enabled || false;
    const token = SlateAjax.mixpanel.token || null;
    const debug = SlateAjax.mixpanel.debug || false;

    if (!enabled || !token) {
        return;
    }

    mixpanel.init(token, {
        debug: debug,
        track_pageview: true,
        persistence: "localStorage",
    });

    mixpanel.track_links(".btn", "Button Clicked", getLinkData);
    mixpanel.track_links(".site-banner .btn", "Banner Button Clicked", getLinkData);
    mixpanel.track_links('.menu-item-btn.btn-login a', "Login Button Clicked (Header)", getLinkData);
    mixpanel.track_links('.menu-item-btn.btn-register a', "Register Button Clicked (Header)", getLinkData);
    mixpanel.track_links('.support-link', "Support Link Clicked", getLinkData);
    mixpanel.track_links('.book-demo-link', "Book Demo Link Clicked", getLinkData);
    mixpanel.track_links('.media-enquiries-link', "Media Enquiries Link Clicked", getLinkData);
    mixpanel.track_links('.permissions-link', "Permissions Link Clicked"), getLinkData;
    mixpanel.track_links('a[href*="sugar-premium-subscription"]', "Sugar Premium Learn More Link Clicked", getLinkData);
    mixpanel.track_links('a[href*="ethanol-premium-subscription"]', "Ethanol Premium Learn More Link Clicked", getLinkData);
    mixpanel.track_links('.pricing-get-started', "Pricing Get Started Button Clicked", getLinkData);

    window.addEventListener('pricing-selector:plan-selected', function (event) {
        mixpanel.track("Pricing: Plan Selected", {
            name: event.detail.plan.title,
        });
    });

    window.addEventListener('pricing-selector:add-ons-selected', function (event) {
        event.detail.addOns.forEach(addOn => {
            mixpanel.track("Pricing: Add-on Selected", {
                name: addOn.title,
                licences: addOn.users,
            });
        });
    });

    window.addEventListener('gform_confirmation_loaded', function (event, form_id) {
        mixpanel.track("Form Submitted", {
            form_id: form_id,
        });
    });

    function onScroll() {
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition >= documentHeight / 2 && !onScroll.logged50) {
            mixpanel.track("Scrolled 50% of the page");
            onScroll.logged50 = true;
        }

        if (scrollPosition >= documentHeight && !onScroll.logged100) {
            mixpanel.track("Scrolled 100% of the page");
            onScroll.logged100 = true;
            window.removeEventListener('scroll', onScroll);
        }
    }

    window.addEventListener('scroll', onScroll);
}
