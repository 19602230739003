export function search() {
    $('[data-toggle-search]').on('click', function (e) {
        e.preventDefault();

        let button = $(this);
        let wrap = $('.site-header').find('.search-form__inner');

        if (!wrap) {
            return;
        }

        button.toggleClass('active');
        $('body').toggleClass('search-active');
        wrap.toggleClass('active');
        wrap.find('input').trigger('focus');
    });
}
