import Flickity from "flickity";
import 'flickity-imagesloaded';
import 'flickity-as-nav-for';
import 'flickity-fade';

/**
 *  Add class after slider is loaded/resized
 */
let resize = Flickity.prototype.resize;

Flickity.prototype._createResizeClass = function () {
    let instance = this;

    instance.element.classList.add('flickity-resized');
};

Flickity.createMethods.push('_createResizeClass');

Flickity.prototype.resize = function () {
    this.element.classList.remove('flickity-resized');
    resize.call(this);
    this.element.classList.add('flickity-resized');
};

/**
 * @param {Flickity} slider
 */
function getSlidesInView(slider) {
    return Math.ceil(slider.size.width / slider.selectedSlide.outerWidth);
}

function disableControlsOnEnd(slider) {
    let slidesInView = getSlidesInView(slider);

    if (!slider.nextButton) {
        return;
    }

    if (slider.selectedIndex >= slider.cells.length - slidesInView) {
        slider.nextButton.element.setAttribute('disabled', '');

        if (slider.selectedIndex > slider.cells.length - slidesInView) {
            slider.select(slider.cells.length - slidesInView);
        }
    } else {
        slider.nextButton.element.removeAttribute('disabled');
    }

    if (slider.nextButton.element.hasAttribute('disabled') && slider.prevButton.element.hasAttribute('disabled')) {
        slider.element.querySelector('.flickity__controls').classList.add('flickity__controls--disabled');
    } else {
        slider.element.querySelector('.flickity__controls').classList.remove('flickity__controls--disabled');
    }
}

function slugifySliderName(name) {
    return name.replace('_', '-');
}

export default function flickitySliders() {
    let elements = document.querySelectorAll('[data-slider]');

    for (var i = 0; i < elements.length; i++) {
        let element = elements[i];

        let name = slugifySliderName(element.dataset.name ?? `slider_${i}`);
        let settings = JSON.parse(element.dataset.settings ?? '{}');

        settings = {
            ...{
                adaptiveHeight: true,
                imagesLoaded: true,
                on: {
                    ready: function () {
                        if (this.prevButton) {
                            this.prevButton.element.innerHTML = Slate.svgs.arrowLeft;
                        }

                        if (this.nextButton) {
                            this.nextButton.element.innerHTML = Slate.svgs.arrowRight;
                        }

                        $(element).find('.flickity-button').wrapAll('<div class="flickity__controls"></div>');

                        disableControlsOnEnd(this);
                    },
                    change: function () {
                        disableControlsOnEnd(this);
                    },
                },
            },
            ...settings,
        };

        Slate.sliders[name] = new Flickity(element, settings);

        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    };

    $('[data-go-to-slide]').on('click', function (e) {
        let slideNumber = parseInt($(this).attr('data-go-to-slide'));
        let sliderName = slugifySliderName($(this).attr('data-go-to-slide-name'));
        let slider = Slate.sliders[sliderName];

        if (!sliderName || !slider) {
            return;
        }

        e.preventDefault();

        slider.select(slideNumber);
    });
}
