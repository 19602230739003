import '../scss/app.scss';

import Theme from "./src/Theme";

import scroll from "./src/scroll";
import helpers from "./src/helpers";
import togglable from "./src/togglable";
import tabs from "./src/tabs";
import menu from "./src/menu";
import smoothScroll from "./src/smoothScroll";
import gravityForms from "./src/third-party/gravityForms";
import flickitySliders from "./src/third-party/flickity";
import popup from "./src/popup";
import { search } from './src/search';
import { loadPowerBiReport } from './src/loadPowerBiReport';

import { createApp } from 'vue';
import PricingSelector from './src/vue/PricingSelector.vue';
import copy from './src/copy';
import { mixpanelInit } from './src/third-party/mixpanel';

/**
 * Load theme JS when init listener is dispatched
 */
window.addEventListener('slate_init', function () {
    let Slate = Theme;
    window.Slate = Slate;

    Slate.setCallables([
        scroll,
        helpers,
        togglable,
        tabs,
        menu,
        smoothScroll,
        gravityForms,
        flickitySliders,
        popup,
        copy,
        search,
        loadPowerBiReport,
        mixpanelInit,
    ]);

    Slate.init();
});

/**
 * Dispatch init event listener when DOM is ready
 */
window.addEventListener('DOMContentLoaded', function () {
    window.dispatchEvent(new Event('slate_init'));
});

/**
 * Vue apps
 */
document.querySelectorAll('[data-pricing-selector]').forEach((element) => {
    createApp(PricingSelector).mount(element);
});
