export default function togglable() {
    $(document).on('click', '[data-toggle-target]', function (e) {
        let el = $(this),
            target = $(document).find(`[data-toggle="${el.attr('data-toggle-target')}"]`),
            collapseSiblings = el.attr('data-collapse-siblings') === 'true';

        if (!target.length) {
            return;
        }

        e.preventDefault();

        let parent = el.parent();

        //---- Open
        if (!target.hasClass('active')) {
            el.addClass('active');
            el.parents('.accordion-2__row').addClass('active').siblings().removeClass('active');
            target.stop().fadeIn({
                duration: Slate.transitions.speed,
                queue: false,
            }).css('display', 'none').slideDown(Slate.transitions.speed).addClass('active');

            if (collapseSiblings) {
                parent.siblings().find('[data-toggle-target]').removeClass('active');
                parent.siblings().find('[data-toggle]').stop().fadeOut({
                    duration: Slate.transitions.speed,
                    queue: false,
                }).slideUp(Slate.transitions.speed).removeClass('active');

                parent.parents('.accordion-2__row').siblings().find('[data-toggle-target]').removeClass('active');
                parent.parents('.accordion-2__row').siblings().find('[data-toggle]').stop().fadeOut({
                    duration: Slate.transitions.speed,
                    queue: false,
                }).slideUp(Slate.transitions.speed).removeClass('active');

                parent.parents('.icon-list__item').siblings().find('[data-toggle-target]').removeClass('active');
                parent.parents('.icon-list__item').siblings().find('[data-toggle]').stop().fadeOut({
                    duration: Slate.transitions.speed,
                    queue: false,
                }).slideUp(Slate.transitions.speed).removeClass('active');
            }

            return;
        }

        if (!collapseSiblings) {
            //---- Close
            el.removeClass('active');
            target.removeClass('active').stop().fadeOut({
                duration: Slate.transitions.speed,
                queue: false,
            }).slideUp(Slate.transitions.speed);
        }
    });
}
