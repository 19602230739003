export default function tabs() {
    //---- Toggle
    $(document).on('click', '[data-tab-target]', function (e) {
        let elem = $(this),
            name = elem.attr('data-tab-target'),
            tabs = $(document).find(`[data-tab="${name}"]`),
            links = $(document).find(`[data-tab-target="${name}"]`),
            siblingTabs = $(document).find(`[data-tab="${name}"]`).siblings('[data-tab]'),
            siblingLinks = links.siblings('[data-tab-target]'),
            transitionEnabled = elem.attr('data-tab-transition') == 'false' ? false : true;

        if (!tabs.length) {
            return;
        }

        e.preventDefault();

        if (tabs.hasClass('active')) {
            return;
        }

        //---- Close other tabs
        siblingLinks.removeClass('active');

        if (transitionEnabled) {
            siblingTabs.stop().fadeOut({
                duration: Slate.transitions.speed,
                queue: false,
            }).slideUp(Slate.transitions.speed);

            setTimeout(function () {
                siblingTabs.removeClass('active');
            }, Slate.transitions.speed);
        } else {
            siblingTabs.hide().removeClass('active');
        }

        //---- Open tab
        links.addClass('active');

        if (transitionEnabled) {
            tabs.stop().fadeIn({
                duration: Slate.transitions.speed,
                queue: false,
            }).css('display', 'none').slideDown(Slate.transitions.speed).addClass('active');
        } else {
            tabs.show().addClass('active');
        }
    });

    //---- Disable click on menu links
    $(document).on('click', '[data-tab-target].menu-item > a', function (e) {
        e.preventDefault();
    });
}
