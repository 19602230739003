window.$ = jQuery;

import iconCross from '../../img/icons/cross.svg?raw';
import iconCaretUp from '../../img/icons/caret-up.svg?raw';
import iconCaretRight from '../../img/icons/caret-right.svg?raw';
import iconCaretDown from '../../img/icons/caret-down.svg?raw';
import iconCaretLeft from '../../img/icons/caret-left.svg?raw';
import iconArrowRight from '../../img/icons/arrow-right.svg?raw';
import iconArrowLeft from '../../img/icons/arrow-left.svg?raw';
import iconPlus from '../../img/icons/plus.svg?raw';
import iconMinus from '../../img/icons/minus.svg?raw';
import iconCheck from '../../img/icons/check.svg?raw';
import iconSpinner from '../../img/icons/spinner.svg?raw';

const Theme = new class {
    constructor() {
        this.window = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        this.elements = {
            body: {
                element: document.querySelector('body'),
                height: 0,
            },
            header: {
                element: document.querySelector('.site-header'),
                height: 0,
            },
        };

        this.breakpoints = {
            menu: 1000,
        };

        this.scroll = {
            offset: 0,
            position: window.scrollY,
            previousPosition: 0,
        };

        this.transitions = {
            speed: 250,
        };

        this.svgs = {
            cross: iconCross,
            caretUp: iconCaretUp,
            caretRight: iconCaretRight,
            caretDown: iconCaretDown,
            caretLeft: iconCaretLeft,
            arrowRight: iconArrowRight,
            arrowLeft: iconArrowLeft,
            plus: iconPlus,
            minus: iconMinus,
            check: iconCheck,
            spinner: iconSpinner,
        };

        this.isTouchDevice = ('ontouchstart' in window) || (navigator.msMaxTouchPoints || navigator.maxTouchPoints) > 2;

        this.sliders = {};

        this.mouse = {
            x: 0,
            y: 0,
        };

        this.animations = {
            scroll: {},
            parallax: {},
            css: {},
            reverse: false,
        };

        this.callables = [];
    }

    setCallables(callables) {
        this.callables = callables;
    }

    setElementHeights() {
        if (!this.elements) {
            return;
        }

        for (const [key, value] of Object.entries(this.elements)) {
            let element = value.element;

            if (!element) {
                return;
            }

            this.elements[key].height = element.offsetHeight;
        }
    }

    onLoad() {
        this.setElementHeights();
    }

    onResize() {
        window.addEventListener('resize', () => {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;

            this.setElementHeights();
        });
    }

    onScroll() {
        window.addEventListener('scroll', () => {
            this.scroll.position = window.scrollY;

            setTimeout(() => {
                this.scroll.previousPosition = this.scroll.position;
            }, 1);
        }, {
            passive: true,
        });
    }

    onMouseMove() {
        window.addEventListener('mousemove', (e) => {
            this.mouse.x = e.clientX;
            this.mouse.y = e.clientY;
        });
    }

    init() {
        this.onLoad();
        this.onResize();
        this.onScroll();
        this.onMouseMove();

        if (this.callables.length > 0) {
            this.callables.forEach(callable => callable());
        }
    }
}

export default Theme;
