import * as pbi from 'powerbi-client';

export function loadPowerBiReport() {
    $(document).on('click', '[data-load-report]', function (e) {
        let button = $(this);
        let data = button.attr('data-load-report');
        let wrap = $(`[data-reports="${$(this).attr('data-wrapper')}"]`);

        if (!data || !wrap) {
            return;
        }

        e.preventDefault();

        wrap.empty();

        let div = document.createElement("div");

        wrap.append(div);

        data = JSON.parse(data);

        wrap.siblings('.placeholder').hide();

        powerbi.embed(div, {
            accessToken: data.token ?? '',
            embedUrl: data.url ?? '',
            id: data.id ?? '',
            tokenType: 1,
            type: 'report'
        });
    });
}
