
import { buttonStartLoadingState } from '@fhoke/helpers';

export default function gravityForms() {
    //---- On form submission
    // Scroll to top
    $(document).on('click', '.gform_button, .gform_previous_button, .gform_next_button', function () {
        let form_btn = $(this);

        $('html, body').animate({
            scrollTop: form_btn.closest('.gform_wrapper, .gform_confirmation_wrapper').offset().top - Slate.elements.header.height
        }, 450);
    });

    // Add loading state
    $(document).on('click', '.gform_button', function () {
        let form_btn = $(this);

        buttonStartLoadingState(form_btn);
    });

    //---- After form is rendered via Ajax
    $(document).on('gform_post_render', function (event, form_id, current_page) {
        let form = $(document).find(`#gform_wrapper_${form_id}`);

        form.find('.gform_submission_error').prepend('<span>Error</span>');
    });
}
